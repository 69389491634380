import React from 'react';
import Home from './Home';
import request from '../../service/request';
import { TRADEHOUSE_POST_SEARCH, RENTHOUSE_SEARCH, NEWHOUSE_SEARCH } from '../../service/api';

async function action ({cookie , fetch}) {
    const [trade, rent, newHouse] = await Promise.all([
        request.POST({
            url: TRADEHOUSE_POST_SEARCH,
            params: {
                // isRecommend: true,
            },
            headers: cookie ? {
                cityCode: cookie['cityCode'] || 320500
            } : {}
        }),
        request.POST({
            url: RENTHOUSE_SEARCH,
            params: {
                isRecommend: true
            },
            headers: cookie ? {
                cityCode: cookie['cityCode'] || 320500
            } : {}
        }),
        request.POST({
            url: NEWHOUSE_SEARCH,
            params: {
                isRecommend: true
            },
            headers: cookie ? {
                cityCode: cookie['cityCode'] || 320500
            } : {}
        })
    ]);

    const houseList = {
        trade: {
            name: '二手房',
            priceCompany: '万',
            moreLink: '/ershoufang/list',
            detailLink: '/ershoufang/detail',
            loading: !trade.data,
            code: trade.code,
            list: trade.data ? trade.data : []
        },
        rent: {
            name: '租房',
            priceCompany: '元/月',
            moreLink: '/zufang/list',
            detailLink: '/zufang/detail',
            loading: !rent.data,
            code: rent.code,
            list: rent.data ? rent.data.data : []
        },
        newHouse: {
            name: '新房',
            priceCompany: '元/m²',
            moreLink: '/xinfang/list',
            detailLink: '/xinfang/detail',
            loading: !newHouse.data,
            code: newHouse.code,
            list: newHouse.data ? newHouse.data.data : []
        }
    };

    return {
        title: '京日找房',
        chunks: ['home'],
        keywords: '京日找房',
        description: '京日找房',
        component: (
            <Home pagedata={houseList} />
        )
    };
}
export default action;
