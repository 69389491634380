import moment from 'moment';

export default {
    /**
     * 获取页面url参数值
     * @param {string} name 'ID'
     * @param {string} propsObj this.props
     */
    getUrlParam (name, propsObj) {
        if (!propsObj) return null;
        const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
        const r = propsObj.search.substr(1).match(reg);
        if (r !== null) return decodeURIComponent(r[2]);
        return null;
    },

    /**
     * 多少天前
     * @param {string} valueTime
     * @param {string} startTime  创建时间
     * @param {string} valType    'day'只返回多少天
     */
    timeFromNow (valueTime, startTime, valType) {
        const newData = Date.parse(new Date(startTime));
        const diffTime = Math.abs(moment(newData).diff(moment(valueTime), 'seconds')) * 1000;
        if (valType === 'day') {
            const dayNum = moment(valueTime).diff(moment(startTime), 'days');
            return `${Math.abs(dayNum) + 1}天前`;
        } else if (diffTime < 24 * 3600 * 1000 && diffTime > 3600 * 1000) { // 一天之内
            const dayNum = moment(newData).diff(moment(valueTime), 'hours');
            return `${dayNum}小时前`;
        } else if (diffTime < 3600 * 1000 && diffTime) { // 一小时之内
            const dayNum = moment(newData).diff(moment(valueTime), 'minutes');
            return `${(dayNum < 0 ? 0 : dayNum)}分钟前`;
        } else {
            const dayNum = moment(newData).diff(moment(valueTime), 'days');
            if (dayNum) return `${dayNum}天前`;
            else return '0分钟前';
        }
    },

    /**
     * 2个值的区间
     * 值相等的时候 只呈现一个
     * 没有值的时候 呈现默认值
     * 值不一样时候呈现区间
     * @param {string} start        开始值
     * @param {string} end          结束值
     * @param {string} tip          间隔符
     * @param {string} unit         单位
     * @param {string} defaultVal   默认值
     */
    numberComparison (start, end, tip, unit, defaultVal) {
        if (start && end) {
            if (start === end) {
                return end + unit;
            } else {
                return start + tip + end + unit;
            }
        } else if (!start && !end) {
            return defaultVal;
        } else {
            return (start || end) + unit;
        }
    },

    /**
     * 数据源 图片 洗成大图
     * @param {object} imgList      数据源
     * @param {string} keyName      字段名
     */
    imgWatermark (imgList, keyName) {
        const newObj = JSON.parse(JSON.stringify(imgList));
        newObj.forEach((item) => {
            item[keyName] = `${item[keyName]}?x-oss-process=style/c_l_sy`;
        });
        return newObj;
    },

    /**
     * 数据源 值解析成
     * {
     *      start: '',
     *      end: ''
     * }
     * @param {object} selectList     选中的数据源
     * @param {object} defaultList    需要对比的数据源
     * @param {string} key            需要对比的字段
     */
    valFind (selectList, defaultList, key) {
        let val = null;

        if (!selectList) {
            return {
                start: '',
                end: ''
            };
        }
        if (typeof (selectList) === 'string') {
            selectList = [selectList];
        }

        selectList.forEach((element) => {
            if (!(defaultList.find(item => item[key] === element))) {
                val = element;
            }
        });
        if (val) {
            val = {
                start: val.split('-')[0],
                end: val.split('-')[1]
            };
        } else {
            val = {
                start: '',
                end: ''
            };
        }
        return val;
    },
}
