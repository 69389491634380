// 新房-物业类型
function formatHouseKeeperType(type) {
    if (type == 1) {
        return '住宅';
    } else if (type == 2) {
        return '别墅';
    } else if (type == 3) {
        return '写字楼';
    } else if (type == 4) {
        return '商铺';
    } else if (type == 5) {
        return '商住';
    } else if (type == 6) {
        return '底商';
    } else if (type == 7) {
        return '商业办公楼';
    } else if (type == 8) {
        return '酒店式公寓';
    } else if (type == 9) {
        return '车库';
    } else if (type == 10) {
        return '工业厂房';
    } else if (type == 11) {
        return '平房';
    }

    return '暂无';
}

// 新房-销售状态
function formatNewHouseStatus(status) {
    if (status == 1) {
        return '即将开盘';
    } else if (status == 2) {
        return '排卡中';
    } else if (status == 3) {
        return '在售';
    } else if (status == 4) {
        return '售罄';
    }

    return '暂无';
}

// 搜索类型
function formatSearchType(type) {
    if (type == 1) {
        return '商圈';
    } else if (type == 2) {
        return '小区';
    }

    return '';
}

module.exports = {
    formatHouseKeeperType, // 新房-物业类型
    formatNewHouseStatus, // 新房-销售状态
    formatSearchType // 搜索类型
}
