import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useState } from 'react';
import Link from '../../components/Link';
import Nothing from "../../components/nothing";
import request from '../../service/request';
import { TRADEHOUSE_SEARCH_NEW, RENTHOUSE_SEARCH_NEW, NEWHOUSE_SEARCH_NEW, SEARCH_ADD_HOT_WORD } from '../../service/api';
import utils from '../../utils';
import format from '../../utils/format';
import s from './index.less';

export default function Home (props) {
    useStyles(s);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        document.addEventListener("click", otherDomClick);
        setStorageDate(JSON.parse(localStorage.getItem('search_history_list') || JSON.stringify({ 0: [], 1: [], 2: [] })));
    }, []);

    const navlink = ['/', '/ershoufang/list', '/zufang/list', '/xinfang/list'];

    const [navFixed, setNavFixed] = useState(false);

    const [storageDate, setStorageDate] = useState();

    const [showSelect, setShowSelect] = useState(false);
    const [showHouseSelect, setShowHouseSelect] = useState(false);

    const [navInfo, setNavInfo] = useState({
        list: ['找二手房', '找租房', '找新房'],
        selectIndex: 0,
        bar: '0px'
    });

    const [searchText, setSearchText] = useState('');

    const [searchInfo, setSearchInfo] = useState({
        dataList: [],
        selectIndex: -1,
        // searchText: '',
        clearHistory: false,
        isGetData: true
    });

    const houseList = props.pagedata || {};

    useEffect(() => {
        if (searchText && searchInfo.isGetData) {
            getList(searchText);
        }
    }, [searchText, setSearchText]);

    const handleScroll = () => {// 滚动呈现
        setNavFixed(window.pageYOffset >= 300);
    };

    const otherDomClick = (e) => {
        if ((e.target.className !== 'select-type' && e.target.className !== 'item')) {
            setShowSelect(false);
        }

        if (e.target.className !== 'search-input' && e.target.className !== 'ellipsis' && e.target.className !== 'info') {
            setShowHouseSelect(false);
        }
    };

    const navClickFun = (items, index) => {
        setNavInfo({
            ...navInfo,
            selectIndex: index,
            bar: `${112 * index}px`
        });
        setSearchText('');
        setSearchInfo({
            ...searchInfo,
            dataList: [],
            // searchText: '',
            isGetData: true
        });
    };

    const setIsShowFun = (flag, keyWord) => {
        setShowHouseSelect(flag);
        if (keyWord !== null || keyWord === '') {
            setSearchText(keyWord);
            setSearchInfo({
                ...searchInfo,
                // searchText: keyWord,
                selectIndex: keyWord === '' ? -1 : searchInfo.selectIndex,
                dataList: [],
                clearHistory: false,
                isGetData: keyWord !== ''
            });
        } else {
            setSearchText(searchText);
            setSearchInfo({
                ...searchInfo,
                dataList: searchText ? searchInfo.dataList : storageDate[navInfo.selectIndex],
                clearHistory: !searchText,
                isGetData: !!searchText,
            });
        }
    };

    const inputKeyDownFun = (e) => {
        const newSearch = { ...searchInfo };
        if (e.keyCode === 40) {// 下键
            if (newSearch.selectIndex === searchInfo.dataList.length - 1) return;
            newSearch.selectIndex++;
        } else if (e.keyCode === 38) {// 上键
            if (newSearch.selectIndex == 0) return;
            newSearch.selectIndex--;
        } else if (e.keyCode === 13) {
            openPage(newSearch.selectIndex,true);
            return;
        } else {
            return;
        }
        if (searchInfo.dataList[newSearch.selectIndex].newHouseName) {
            setSearchText(searchInfo.dataList[newSearch.selectIndex].newHouseName);
        } else {
            setSearchText(searchInfo.dataList[newSearch.selectIndex].estateName || searchInfo.dataList[newSearch.selectIndex].tradeAreaName || searchInfo.dataList[newSearch.selectIndex].countryAreaName);
        }
        setSearchInfo({
            ...searchInfo,
            dataList: [],
            clearHistory: false,
            selectIndex: newSearch.selectIndex,
            // searchText: searchInfo.dataList[newSearch.selectIndex].houseName
        });
    };

    const inputChangeFun = (e) => {
        setShowHouseSelect(false);
        if (e.target.value.replace(/\s+/g, '') === '') {
            setIsShowFun(false, '');
        } else {
            setIsShowFun(false, e.target.value);
        }
    };

    const brightKeyword = (val, keyWord) => {
        const valKey = keyWord.replace(/\s+/g, '');
        if (!val) {
            return '';
        } else if (val.indexOf(valKey) !== -1) {
            return val.replace(valKey, `<strong style="color: #1677FF;font-weight: normal">${valKey}</strong>`);
        } else {
            return val;
        }
    };

    // 处理房源数量展示
    const handleHouseNumber = (houseNumber, houseType) => {
        if (!houseNumber || houseNumber === 0) {
            return '';
        } else if (houseType === '租房') {
            return `在租${houseNumber}套`;
        } else {
            return `在售${houseNumber}套`;
        }
    };

    const getList = (keyWord) => {
        request.POST({
            url: [TRADEHOUSE_SEARCH_NEW, RENTHOUSE_SEARCH_NEW, NEWHOUSE_SEARCH_NEW][navInfo.selectIndex],
            params: {
                text: keyWord
            }
        }).then((res) => {
            if (res.data) {
                setSearchInfo({
                    ...searchInfo,
                    dataList: res.data,
                    clearHistory: false,
                    isGetData: false
                });
                setShowHouseSelect(true);
            }
        }).catch((error) => {
            console.log(error, 'error');
        });
    };

    // 热词添加接口
    const addHotWord = (key) => {
        request.POST({
            url: SEARCH_ADD_HOT_WORD,
            params: {
                keyWord: key
            }
        }).catch((error) => {
            console.log(error, 'error');
        })
    }

    const openPage = (index,isEnterKey=false) => {
        let houseName = '';
        let newHouseId = '';
        let address = '';
        let isNewHousePremises = false;
        if (navInfo.list[navInfo.selectIndex] === '找新房' && index > -1) {
            houseName = index !== null && index > -1 ? searchInfo.dataList[index].newHouseName : searchText;
            address = index !== null && index > -1 ? searchInfo.dataList[index].newHouseAddress : '';
            newHouseId = searchInfo.dataList[index].newHouseId ? searchInfo.dataList[index].newHouseId : '';
            isNewHousePremises = searchInfo.dataList[index].searchType === 2;
        } else {
            houseName = (index !== null && index > -1 )? searchInfo.dataList[index].estateName || searchInfo.dataList[index].tradeAreaName || searchInfo.dataList[index].countryAreaName : searchText;
            address = index !== null && index > -1 ? searchInfo.dataList[index].address : '';
        }
        setShowHouseSelect(false);
        setSearchText('');
        setSearchInfo({
            ...searchInfo,
            dataList: [],
            clearHistory: false,
            selectIndex: index || -1,
            // searchText: houseName,
            isGetData: false
        });
        //判断当前搜索是新房/租房/二手房
        let query = ""
        if(!isEnterKey){
            const {searchType,tradeAreaId,estateId,estateName} = searchInfo.dataList[index]
            if(estateId){
                switch(navInfo.selectIndex){
                    case 0://'二手房'
                        if(searchType == 1){//商圈
                            query +=`tradeAreaId=${tradeAreaId}`
                        }else{//小区
                            query +=`locationEstateId=${estateId}&searchEstateName=${estateName}`
                        }
                        break;
                    case 1://'租房'
                        if(searchType == 1){//商圈
                            query +=`tradeAreaId=${tradeAreaId}`
                        }else{//小区
                            query +=`estateId=${estateId}&searchEstateName=${estateName}`
                        }
                        break;
                    case 2:// '新房'
                        query +=`tradeAreaId=${tradeAreaId}`
                        break;
                    default:
                        break;
                }
            }else{
                query +=`searchEsText=${houseName}&tradeAreaId=${tradeAreaId}&isEsSearch=${true}`
            }
        }else{
            query +=`searchEsText=${houseName}&isEsSearch=${true}`
        }
        openLink(houseName, newHouseId, address, isNewHousePremises,query);
    };

    const openLink = (houseName, newHouseId, address, isNewHousePremises,query) => {
        // 失去光标
        // var input = document.querySelector('#search-input') as HTMLInputElement;
        // input.blur();

        if (houseName) cleanHistory(houseName, address);

        let link = '';
        if (isNewHousePremises) {
            link = `/xinfang/detail${newHouseId ? `?id=${newHouseId}` : ''}`;
            addHotWord(houseName);
        } else {
            link = navlink[navInfo.selectIndex + 1] + (houseName ? `?housename=${houseName}&${query}` : '');
        }
        setTimeout(() => {
            window.open('_blank').location = link;
        }, 800);
    }

    const cleanHistory = (houseName, address) => {
        const newObj = { ...storageDate };
        const item = {
            houseName,
            address
        };
        const i = newObj[navInfo.selectIndex].findIndex((items) => items.houseName === houseName);
        if (i >= 0) {
            newObj[navInfo.selectIndex].splice(i, 1);
        }
        newObj[navInfo.selectIndex].push(item);
        localStorage.setItem('search_history_list', JSON.stringify(newObj));
    }

    const delHistory = () => {
        const newObj = { ...storageDate };
        newObj[navInfo.selectIndex] = [];
        localStorage.setItem('search_history_list', JSON.stringify(newObj));
        setStorageDate(newObj);
    }

    const imgError = (e) => {
        e.target.onerror = null;
        e.target.src = require("../../images/common/default-img.png");
    }
    const openMapFind=(e)=>{
        window.open('_self').location = '/mapfind';
    }

    return (
        <div className="page-home">
            <div className="banner-info">欢迎使用 京日找房</div>
            <div className="search-box">
                <div className="page-nav">
                    <ul className="nav">
                        {navInfo.list.map((items, index) => {
                            return <li key={index} className={navInfo.selectIndex === index ? 'active' : ''} onClick={() => navClickFun(items, index)}>{items}</li>
                        })}
                    </ul>
                    <div className="nav-bar" style={{ left: navInfo.bar }}>
                        <i />
                    </div>
                </div>
                <div className={navFixed ? "search-content fixed" : "search-content"}>
                    <div className="search">
                        <span className="select-type" onClick={() => navFixed ? setShowSelect(!showSelect) : null}>{navInfo.list[navInfo.selectIndex]}</span>
                        {navFixed && showSelect ?
                            <div className="all-type">
                                {navInfo.list.map((items, index) => {
                                    return <div key={index} className={navInfo.selectIndex === index ? 'item active' : 'item'} onClick={(e) => navClickFun(e, index)}>{items}</div>
                                })}
                            </div> : null}
                        {navFixed ? (showSelect ? <i className="iconfont icon-up">&#xe792;</i> : <i className="iconfont icon-down">&#xe794;</i>) : <></>}
                        <i className="tip" />
                        <input type="text" id="search-input" className="search-input"
                            placeholder="请输入区域、商圈或小区名开始找房"
                            autoComplete="off"
                            value={searchText}
                            onChange={(e) => inputChangeFun(e)}
                            onFocus={() => setIsShowFun(true)}
                            onKeyDown={(e) => inputKeyDownFun(e)} />
                        {
                            showHouseSelect && searchInfo.dataList.length ? <div className="other">
                                {searchInfo.clearHistory ? <div className="clear-history" onClick={() => delHistory()}>历史记录<i className="iconfont icon-del">&#xe7a5;</i></div> : <></>}
                                {searchInfo.dataList.map((items, index) => {
                                    return (navInfo.list[navInfo.selectIndex] === '找新房'
                                        ? <div key={index}
                                               className={searchInfo.selectIndex === index ? 'active ellipsis' : 'ellipsis'}
                                               onClick={() => openPage(index)}>
                                            {items.searchType == 1?<span className="tag tagPad first"
                                                dangerouslySetInnerHTML={{__html: '商圈'}}/>:
                                                <span className={items.searchType ? 'tag tagPad first' : 'tag first'}
                                                dangerouslySetInnerHTML={{__html: format.formatHouseKeeperType(items.houseKeeperType)}}/>
                                           }
                                            <span className="info ellipsis"
                                                  dangerouslySetInnerHTML={{__html: brightKeyword(items.newHouseName || items.houseName || items.tradeAreaName, searchText)}}/>

                                             {/* 除商圈外 区域  吴中-万达  */}
                                             {
                                                items.searchType != 1?<span className="area"
                                                dangerouslySetInnerHTML={{__html: `${items.countryAreaName||''}${items.countryAreaName&&items.tradeAreaName?'-':''}${items.tradeAreaName||''}`}}/>:<></>
                                            }
                                            {items.searchType == 1?<span className="number last"dangerouslySetInnerHTML={{__html: handleHouseNumber(items.houseNumber, '新房')}}/>:
                                                        <> { 
                                                            items.searchType ?<>{ 
                                                                items.salePrice ?
                                                                    <span className="number last" dangerouslySetInnerHTML={{__html: `${format.formatNewHouseStatus(items.newHouseStatus)} ${items.salePrice || 0}元/㎡起`}}/>:
                                                                    <span className="number last" dangerouslySetInnerHTML={{__html: `${format.formatNewHouseStatus(items.newHouseStatus)}  价格待定`}}/>
                                                            }
                                                </>
                                                            :<></>
                                                        }
                                                        </>
                                           }
                                        </div>
                                        : <div key={index}
                                               className={searchInfo.selectIndex === index ? 'active ellipsis' : 'ellipsis'}
                                               onClick={() => openPage(index)}>
                                            {items.searchType ? <span className={items.searchType ? 'tag tagPad first' : 'tag first'}
                                                                     dangerouslySetInnerHTML={{__html: format.formatSearchType(items.searchType)}}/>
                                            : <></>}
                                            <span className="info ellipsis"
                                                  dangerouslySetInnerHTML={{__html: brightKeyword(items.estateName || items.tradeAreaName || items.countryAreaName || items.houseName, searchText)}}/>
                                            <div className="information">
                                                {navInfo.list[navInfo.selectIndex] === '找二手房'
                                                    ? items.averagePrice ? <span className="price" dangerouslySetInnerHTML={{__html: `${items.averagePrice}元/㎡起`}}/>
                                                        : <>
                                                            {
                                                               items.searchType!=1? <span className="price" dangerouslySetInnerHTML={{__html: `价格待定`}}/>:null
                                                            }
                                                        </>
                                                    : <></>
                                                }
                                                {navInfo.list[navInfo.selectIndex] === '找租房'
                                                    ? <span className="number last"
                                                            dangerouslySetInnerHTML={{__html: handleHouseNumber(items.houseNumber, '租房')}}/>
                                                    :
                                                    <span className="number last"
                                                          dangerouslySetInnerHTML={{__html: handleHouseNumber(items.houseNumber, '二手房')}}/>
                                                }
                                            </div>
                                        </div>)
                                })}
                            </div> : null
                        }
                        {searchText ? <span className="del-btn" onClick={() => { setIsShowFun(false, ''); }}><i className="iconfont icon-del">&#xe787;</i></span> : null}
                        <div className='map_entry' onClick={()=>openMapFind()}>
                            <img className='icon' src="https://static.jingrizf.com/file/13caa287-fd6e-46d6-bdcb-1ca175b04f31.png"></img>
                            地图
                        </div>
                        <span className="search-btn" onClick={() => openPage(-1,true)}><i className="iconfont icon-look">&#xe77e;</i></span>
                    </div>
                </div>
            </div>
            {Object.keys(houseList).map((item, index) => {
                return (houseList[item].list.length != 0 ? (<div className="house-list" key={index}>
                    <div className="top-title">
                        <span className="name">{houseList[item].name}</span>
                        <a className="right-more" target="_blank" href={houseList[item].moreLink}>查看更多<i className="iconfont icon-more">&#xe783;</i> </a>
                    </div>
                    {houseList[item].code === 0 ?
                        <ul>
                            {!houseList[item].loading ?
                                (houseList[item].list.map((items) => {
                                    return (<li key={items.houseId}>
                                        <Link to={`${houseList[item].detailLink}?id=${items.houseId}`} target="_blank" >
                                            <div className="vr">
                                                <img onError={(e) => imgError(e)} src={items.src ? `${items.src}?x-oss-process=style/c_s_sy` : require("../../images/common/detail-default.png")} />
                                                {item === 'newHouse' ?
                                                    <i className="tip statusF">{items.newHouseStatusF}</i>
                                                    : (item === 'trade' ?
                                                        (items.isExcellent ?
                                                            <i className="tip"><img src={require("../../images/common/bixuanhaofang-logo.png")} /><span className="tip-logo">必选好房</span></i> :
                                                            (items.isRecommend ?
                                                                <i className="tip status"><img src={require("../../images/common/dianzhanglijian-logo.png")} className="tip-logo" />店长力荐</i> : null))
                                                        : null)}
                                                {item === 'newHouse' && items.statusF ? <i className="tip status">{items.statusF}→</i> : null}
                                                {item === 'trade' && items.isVr || item === 'newHouse' && items.isVr?
                                                    <div className="VR-logo">
                                                        <img src="https://static.jingrizf.com/wx/file/1621999051613.gif" alt="" />
                                                    </div> : null}
                                            </div>
                                            <div className="house-name ellipsis">{items.houseName}</div>
                                            <div className="house-bottom">
                                                <p className="address ellipsis">
                                                    {
                                                        item === 'newHouse' ?
                                                            (items.countryAreaName || items.tradeAreaName || items.newHouseAddress ? (items.countryAreaName || '') + (items.tradeAreaName || '') + (items.newHouseAddress || '') : null)
                                                            :
                                                            <>
                                                                {`${items.rooms}室${items.parlor}厅${items.bathroom}卫${items.kitchen}厨`}<i />
                                                                {items.area}㎡<i />
                                                                {items.orientations}
                                                            </>
                                                    }
                                                </p>
                                                <p>
                                                    <span>{items.salePrice ? items.salePrice + houseList[item].priceCompany : '价格待定'}</span>
                                                    <span>
                                                        {item === 'trade' && items.unitPrice ? `${items.unitPrice}元/m²` : ''}
                                                        {item === 'newHouse' ? utils.numberComparison(items.areaStart, items.areaEnd, '-', '㎡', '暂无数据') : ''}
                                                    </span>
                                                </p>
                                            </div>
                                        </Link>
                                    </li>
                                    )
                                })) : <div className="page-loading">
                                    <img src={require("../../images/common/loading.png")} />
                                    <p>数据正在加载...</p>
                                </div>}
                        </ul> : <Nothing pagetype={houseList[item].code} />}
                </div>)
                    : null)
            })}
        </div>
    );
}
